import {
  ApplicationConfig,
  HostListener,
  Injectable,
  signal,
} from '@angular/core';

export type Toast = {
  message: string;
  type: 'error' | 'info' | 'success';
  id: number;
};

@Injectable({
  providedIn: 'root',
})
export class ToastPopoverService {
  toasts = signal<Array<Toast>>([]);
  #toastId = 0;

  public addToast(message: string, type: 'error' | 'info' | 'success') {
    this.toasts.update((toasts) => [
      ...toasts,
      { message, type, id: ++this.#toastId },
    ]);
  }
  public removeToast(id: number) {
    this.toasts.update((toasts) => toasts.filter((toast) => toast.id !== id));
  }

  #wasOffline = signal(false);
  @HostListener('window:offline') onOffline(): void {
    this.#wasOffline.set(true);
    this.addToast(
      'You are currently offline. Please check your internet connection',
      'error',
    );
  }
  @HostListener('window:online') onOnline(): void {
    if (this.#wasOffline()) {
      this.addToast('You are back online', 'info');
    }
    this.#wasOffline.set(false);
  }
}

export function provideToastPopoversService(): ApplicationConfig['providers'] {
  return [ToastPopoverService];
}
